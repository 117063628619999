export enum ProjectType {
  RESEARCH = 'RESEARCH',
  MATRICULATION = 'MATRICULATION',
  LICENCE = 'LICENCE',
  SERVICE = 'SERVICE',
  QUALIFICATION = 'QUALIFICATION',
}

export function getProjectTypeCode(type: ProjectType) {
  switch (type) {
    case ProjectType.MATRICULATION:
      return 'I';
    case ProjectType.LICENCE:
      return 'L';
    case ProjectType.QUALIFICATION:
      return 'Q';
    case ProjectType.RESEARCH:
      return 'F';
    case ProjectType.SERVICE:
      return 'S';
  }
}

export function getProjectTypeFromCode(code: string): ProjectType {
  switch (code.toUpperCase()) {
    case 'I':
      return ProjectType.MATRICULATION;
    case 'L':
      return ProjectType.LICENCE;
    case 'Q':
      return ProjectType.QUALIFICATION;
    case 'F':
      return ProjectType.RESEARCH;
    case 'S':
      return ProjectType.SERVICE;
  }
  throw new Error(`Invalid Project Type Code: ${ code }`);
}
