import type { Dictionary, List, PropertyPath, ValueIteratee } from 'lodash';
import { get, isNil } from 'lodash';
import { SafeAny } from '@project-hub/types';
import { ConditionalPick } from 'type-fest';

// from https://github.com/jonschlinkert/isobject
export function isObject(val: SafeAny): val is object {
  return !isNil(val) && typeof val === 'object' && Array.isArray(val) === false;
}

// from https://github.com/jonschlinkert/is-plain-object
function isObjectObject(o: SafeAny): o is object {
  return isObject(o) && Object.prototype.toString.call(o) === '[object Object]';
}

export function isPlainObject(o: SafeAny): o is object {
  if (isObjectObject(o) === false) {
    return false;
  }

  // If has modified constructor
  const ctor = o.constructor;
  if (typeof ctor !== 'function') {
    return false;
  }

  // If has modified prototype
  const prot = ctor.prototype;
  if (isObjectObject(prot) === false) {
    return false;
  }

  // If constructor does not have an Object-specific method
  // eslint-disable-next-line no-prototype-builtins
  if (prot.hasOwnProperty('isPrototypeOf') === false) {
    return false;
  }

  // Most likely a plain Object
  return true;
}

export function isUndefined(val: SafeAny): val is undefined {
  return typeof val === 'undefined'; // undefined can be overwritten in some environments
}

/**
 * Creates a dictionary from a collection of objects by a given iteratee
 * @param collection a collection of objects
 * @param iteratee property of the object or function to retrieve a value
 */
export function mapBy<T>(collection: List<T> | null | undefined, iteratee?: ValueIteratee<T>): Dictionary<T> {
  if (isNil(collection)) {
    return {};
  }
  return Array.from(collection).reduce(
    (previousValue, currentValue) => {
      const key = typeof iteratee === 'function' ? iteratee(currentValue) : get(currentValue, iteratee as PropertyPath);
      previousValue[key] = currentValue;
      return previousValue;
    },
    {} as Dictionary<T>,
  );
}

/**
 * Creates a dictionary from another dictionary of objects by a given iteratee
 * @param dict a dictionary of objects
 * @param iteratee property of the object or function to retrieve a value
 */
export function mapObjectValues<T, S>(dict: Record<string, T>, iteratee: (value: T, key: string) => S): Record<string, S>;
export function mapObjectValues<T, S extends keyof ConditionalPick<T, string | number>>(dict: Record<string, T>, iteratee: S): Record<string, T[S]>;
export function mapObjectValues(
  dict: Record<string, SafeAny>,
  iteratee: ((value: SafeAny, key: string) => SafeAny) | string,
): Record<string, SafeAny> {
  return Object.keys(dict).reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = typeof iteratee === 'string'
        ? dict[currentValue][iteratee]
        : iteratee(dict[currentValue], currentValue);
      return previousValue;
    },
    {} as Record<string, SafeAny>, // Initialize an empty object with the appropriate type
  );
}
