import type { DefaultContext } from '@apollo/client/core';
import type { GraphQLFormattedError } from 'graphql';
import type { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { SafeAny } from '@project-hub/types';
import { MutationResult, SubscriptionResult } from 'apollo-angular';

export interface BaseResult<TData extends Record<string, SafeAny>> {
  data?: TData | null;
}

export interface QueryResult<
  TData extends Record<string, SafeAny> = Record<string, SafeAny>,
  TContext = DefaultContext,
  TExtensions = Record<string, SafeAny>
> extends BaseResult<TData> {
  context?: TContext;
  errors?: ReadonlyArray<GraphQLFormattedError>;
  extensions?: TExtensions;
}

export function queryData<
  TResult extends BaseResult<SafeAny>,
  TData extends NonNullable<TResult['data']>,
  TQuery extends keyof TData,
>(
  query: TQuery,
): OperatorFunction<TResult, TData[TQuery]> {
  return map((queryResult) => {
    return queryResult.data[query];
  });
}

/**
 * @deprecated Use `queryData` instead
 */
export function mutationData<R extends SafeAny, M extends keyof R>(
  mutation: M,
): OperatorFunction<MutationResult<R>, NonNullable<MutationResult<R>['data']>[M]> {
  return map((mutationResult: MutationResult<R>) =>
    (mutationResult.data as SafeAny)?.[mutation as keyof typeof mutationResult.data],
  );
}

/**
 * @deprecated Use `queryData` instead
 */
export function subscriptionData<S extends keyof SubscriptionResult<T>['data'], T extends SafeAny = SafeAny>(
  subscription: S,
): OperatorFunction<(SubscriptionResult<T>), SubscriptionResult<T>['data'][S]> {
  return map((subscriptionResult) => {
    return (subscriptionResult.data as SafeAny)?.[subscription as keyof typeof subscriptionResult.data] ?? subscriptionResult[subscription as keyof typeof subscriptionResult];
  });
}
