import { isNil } from 'lodash';
import type { MonoTypeOperatorFunction, Observable, ObservableInput, ObservedValueOf, OperatorFunction, ReplaySubject } from 'rxjs';
import { defer, EMPTY, observable, of } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SafeAny } from '@project-hub/types';


export function debug<T>(tag: string): MonoTypeOperatorFunction<T> {
  return tap<T>({
    next(value) {
      // eslint-disable-next-line no-console
      console.log(`%c[${ tag }: Next]`, 'background: #009688; color: #fff; padding: 3px; font-size: 9px;', value);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.log(`%c[${ tag }: Error]`, 'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;', error);
    },
    complete() {
      // eslint-disable-next-line no-console
      console.log(`%c[${ tag }: Complete]`, 'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;');
    },
  });
}

export function debugTable<T>(): MonoTypeOperatorFunction<T> {
  return tap<T>({
    next(value) {
      // eslint-disable-next-line no-console
      console.table(value);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.log(`%c[Error]`, 'background: #E91E63; color: #fff; padding: 3px; font-size: 9px;', error);
    },
    complete() {
      // eslint-disable-next-line no-console
      console.log(`%c[Complete]`, 'background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;');
    },
  });
}

export function getReplaySubjectValue<T>(subject: ReplaySubject<T>, index = 0): T {
  // eslint-disable-next-line no-underscore-dangle
  return (subject as SafeAny)._buffer?.[index];
}

export function ofFunction<T>(func: () => T) {
  return of(null).pipe(map(func));
}

export function filterNil<T>(): OperatorFunction<T, NonNullable<T>> {
  return filter(value => value !== undefined && value !== null) as unknown as OperatorFunction<T, NonNullable<T>>;
}

export function equals<T>(v1: T, strict = true): OperatorFunction<T, boolean> {
  return map<T, boolean>((v2: T) => {
    // eslint-disable-next-line eqeqeq
    return strict ? v1 === v2 : v1 == v2;
  });
}

export function isIn<T>(...v1: T[]): OperatorFunction<T, boolean> {
  return map<T, boolean>((v2: T) => {
    return v1.indexOf(v2) !== -1;
  });
}

export function mapArray<S, T>(callbackfn: (value: S, index: number, array: S[]) => T, thisArg?: SafeAny) {
  return map<S[], T[]>((value: S[]) => value?.map(callbackfn, thisArg));
}

export function filterArray<S>(predicate: (value: S, index: number, array: S[]) => unknown, thisArg?: SafeAny) {
  return map<S[], S[]>((value: S[]) => value.filter(predicate, thisArg));
}

export function filterArrayNil<T>(): OperatorFunction<T[], NonNullable<T>[]> {
  return map<T[], NonNullable<T>[]>((value: T[]) => value.filter(v => !isNil(v)) as NonNullable<T>[]);
}

export function switchFilterMap<T, O extends ObservableInput<any>>(
  filterPredicate: (value: T, index: number) => boolean,
  project: (value: T, index: number) => O,
): OperatorFunction<T, ObservedValueOf<O>> {
  return switchMap<T, O>((value, index) => filterPredicate(value, index) ? project(value, index) : EMPTY as SafeAny);
}

export function takeWhileActive<T, O extends Observable<boolean>>(filterObservable: O): MonoTypeOperatorFunction<T> {
  return (input) => {
    return input.pipe(
      withLatestFrom(filterObservable),
      filter(([, isActive]) => !!isActive),
      map(([value]) => value),
    );
  };
}

export function skipWhileActive<T, O extends Observable<boolean>>(filterObservable: O): MonoTypeOperatorFunction<T> {
  return (input) => {
    return input.pipe(
      withLatestFrom(filterObservable),
      filter(([, isActive]) => !isActive),
      map(([value]) => value),
    );
  };
}

export function mapLatestFrom<T, O extends ObservableInput<any>>(project: O): OperatorFunction<T, ObservedValueOf<O>> {
  return (input): Observable<ObservedValueOf<O>> => {
    return input.pipe(
      withLatestFrom(project),
      map(([, value]) => value),
    );
  };
}

export function doOnSubscribe<T>(onSubscribe: () => void): MonoTypeOperatorFunction<T> {
  return function inner(source: Observable<T>): Observable<T> {
    return defer(() => {
      onSubscribe();
      return source;
    });
  };
}

export function fixApolloObservable<T>(obs: T): SafeAny {
  (obs as SafeAny)[observable] = () => obs;
  return obs;
}
