import { isNil, sortBy } from 'lodash';

interface UserRoleWithCostRates {
  startingMonth: number;
  startingYear: number;
  endingMonth?: number | null;
  endingYear?: number | null;
  role: {
    costRates: {
      year: number;
      costRate: number
    }[]
  };
}

export function getCostRate(userRoles: UserRoleWithCostRates[], date: Date): number {
  userRoles = sortBy(userRoles, 'startingYear', 'startingMonth').reverse();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  for (const userRole of userRoles) {
    if (year < userRole.startingYear || (year === userRole.startingYear && month < userRole.startingMonth)) {
      continue;
    }
    if (!isNil(userRole.endingYear) && !isNil(userRole.endingMonth)) {
      if (year > userRole.endingYear || (year === userRole.endingYear && month > userRole.endingMonth)) {
        continue;
      }
    }
    for (const costRate of userRole.role.costRates) {
      if (costRate.year === year) {
        return costRate.costRate;
      }
    }
  }
  return 0;
}

interface UserRole {
  startingMonth: number;
  startingYear: number;
  endingMonth?: number | null;
  endingYear?: number | null;
  roleId: string;
}

export function getRoleId(userRoles: UserRole[], date: Date): string | null {
  userRoles = sortBy(userRoles, 'startingYear', 'startingMonth').reverse();
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  for (const userRole of userRoles) {
    if (year < userRole.startingYear || (year === userRole.startingYear && month < userRole.startingMonth)) {
      continue;
    }
    if (!isNil(userRole.endingYear) && !isNil(userRole.endingMonth)) {
      if (year > userRole.endingYear || (year === userRole.endingYear && month > userRole.endingMonth)) {
        continue;
      }
    }
    return userRole.roleId;
  }
  return null;
}
