export function fixTimezonedDate(date: Date) {
  if (date.getUTCMilliseconds() === 0 && date.getUTCSeconds() === 0 && date.getUTCMinutes() === 0 && date.getUTCHours() === 0) {
    return date;
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
}

export function parseUtcDateOnly(date: string) {
  const parts = date
    .split('T')[0]
    .split('-')
    .map(part => parseInt(part, 10));
  return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
}
